#map_party {
	border:1px #f00 solid;
	height: 300px;
}
#index {
	#hero {
		position: relative;
		width: auto;
		height: 580px;
		overflow: hidden;
		.caro_inner {
			margin: 0;
			width: 100%;
			height: 100%;
			&.caro_dots {
				padding-bottom: 0;
			}
		}
		.caro_slide {
			height: 100%;
			color: #fff;
			font-weight: 900;
			text-align: center;
			background-size: cover;
			background-position: center;
			box-sizing: border-box;
		}
		.type {
			display: none;
			font-size: 160px;
		}
		.storage {
			display: none;
			font-size: 110px;
		}
		#rv {
			padding-top: 200px;
			background-image: url(../../static/images/bg_rv_storage.jpg);
		}
		#boat {
			padding-top: 180px;
			background-image: url(../../static/images/bg_boat_storage.jpg);
			.type {
				line-height: 0.7;
				transform: translateX(240px);
			}
		}
		#vehicle {
			padding-top: 50px;
			background-image: url(../../static/images/bg_vehicle_storage.jpg);
			background-position: 30% 0;
			.type, .storage {
				transform: translateX(-220px);
			}
		}
		.dots_container {
			top: 50%;
			left: 10%;
			margin: auto;
			width: auto;
			height: 175px;
			transform: translate(-50%,-50%);
			.caro_dot {
				display: block;
				background: rgba(#fff,0.6);
				border: 0;
				box-shadow: none;
				margin: 15px 0;
				&.active {
					background: #fff;
				}
			}
		}
		.btn {
			position: absolute;
			display: block;
			width: 370px;
			left: 0;
			font-size: 28px;
			right: 0;
			bottom: 50px;
			margin: 0 auto;
			border: 0;
			padding: 20px 0;
			box-shadow: 0 0 0 10px rgba(#fff,0.5);
			transition: all 0.4s;
			&:hover {
				box-shadow: 0 0 10px 10px rgba(#fff,0.65);
			}
		}
	}
	#tabs {
		position: relative;
		padding: 25px 0;
		background: linear-gradient(to right, $primary_light, $primary, $primary_light);
		&:before, &:after {
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 15px;
			background: -webkit-gradient(linear, left top, right top, from(#fff), color-stop(#959595), to(#fff));
			background: linear-gradient(to right, #fff, #959595, #fff);
		}
		&:after {
			top: auto;
			bottom: 0;
		}
		.wrap {
			display: flex;
			max-width: 1300px;
			width: auto;
			margin: 0 auto;
		}
		.tab {
			position: relative;
			z-index: 5;
			width: 33.33%;
			font-size: 48px;
			font-weight: 900;
			color: #fff;
			text-align: center;
			padding: 10px 2%;
			cursor: pointer;
			border-right: 1px solid #210f05;
			transition: color 0.3s;
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 50px 50px 0 50px;
				border-color: $primary transparent transparent transparent;
				transform: scaleY(0);
				transform-origin: top;
				transition: transform 0.3s;
			}
			&:first-of-type:after {
				border-color: #522d0f transparent transparent transparent;
			}
			&:last-of-type {
				border-right: 0;
				&:after {
					border-color: #4a280d transparent transparent transparent;
				}
			}
			.text {
				position: relative;
				z-index: 10;
				display: block;
				padding: 15px 0;
				transition: background 0.25s, box-shadow 0.4s;
				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					margin: 0 auto;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 40px 40px 0 40px;
					border-color: #fff transparent transparent transparent;
					transform: scaleY(0);
					transform-origin: top;
					transition: transform 0.3s;
					filter: drop-shadow(0px 6px 3px rgba(#000,0.7));
				}
			}
			&:hover {
				color: $accent;
				.text {
					background: #fff;
					box-shadow: 0 2px 10px 0 rgba(#000,0.7);
				}
			}
			&.active {
				color: $primary;
				&:after {
					transform: scaleY(1);
				}
				.text {
					background: #fff;
					box-shadow: 0 2px 10px 0 rgba(#000,0.7);
					&:after {
						transform: scaleY(1);
					}
				}
			}
		}
	}
	#tab_contents {
		.content {
			display: none;
			&.active {
				display: flex;
			}
		}
	}
	#features, #map_info {
		display: flex;
	}
	#features_content {
		width: 50%;
		.wrap {
			display: flex;
			justify-content: center;
			flex-direction: column;
			max-width: 500px;
			width: 100%;
			height: 100%;
			float: right;
			padding: 0 4%;
			box-sizing: border-box;
		}
		.feat {
			margin: 30px 0;
		}
		.icon {
			display: inline-block;
			width: 30px;
			margin-right: 25px;
			img {
				max-width: 100%;
			}
		}
		.text {
			display: inline-block;
			font-size: 18px;
			width: calc(100% - 60px);
		}
	}
	#features_gallery {
		width: 50%;
		.caro_inner {
			margin: 0;
			width: 100%;
			height: 100%;
			&.caro_dots {
				padding-bottom: 0;
			}
		}
		.dots_container {
			top: 50%;
			left: 0;
			margin: auto;
			width: auto;
			height: auto;
			padding: 10px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			transform: translateY(-50%);
			background-color: #fff;
			.caro_dot {
				display: block;
				background: rgba($primary,0.5);
				border: 0;
				box-shadow: none;
				&.active {
					background: $primary;
				}
			}
		}
	}
	#about {
		background: {
			image: url(../../static/images/bg_about.jpg);
			position: center;
			size: cover;
		}
		color: #fff;
		padding: 100px 20px;
		box-sizing: border-box;
		.wrap {
			max-width: 930px;
			width: auto;
			margin: 0 auto;
		}
		h1 {
			font-size: 36px;
			font-weight: 900;
			margin-bottom: 15px;
		}
		p {
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 15px;
		}
		.btn {
			font-size: 18px;
			padding: 15px 50px;
			margin-top: 20px;
		}
	}
	#facility_info {
		width: 50%;
		font-size: 18px;
		box-sizing: border-box;
		.wrap {
			max-width: 600px;
			width: 100%;
			float: right;
			margin: 50px 0;
			padding: 0 4%;
			box-sizing: border-box;
		}
		h2 {
			font-size: 36px;
			font-weight: 700;
		}
		.section {
			margin: 30px 0;
			strong {
				display: block;
				font-weight: 800;
			}
			a {
				color: #000;
				text-decoration: none;
			}
		}
		.btn {
			padding: 15px 30px;
		}
	}
	#map_canvas {
		width: 50%;
	}

	@media screen and (max-width: 1100px) {
		#hero {
			.dots_container {
				left: 5%;
			}
			.type {
				font-size: 100px;
				display: block;
			}
			.storage {
				font-size: 70px;
				display: block;
			}
			#rv {
				background-image: url(../../static/images/rv_storage.jpg);
				.type {
					display: inline-block;
				}
				.storage {
					display: inline-block;
					margin-top: 50px;
				}
			}
			#boat {
				background-image: url(../../static/images/boat_storage.jpg);
				.type {
					transform: translateX(120px);
				}
			}
			#vehicle {
				background-image: url(../../static/images/vehicle_storage.jpg);
				background-position: center;
				.type, .storage {
					transform: translateX(-150px);
				}
			}
		}
		#tabs {
			.tab {
				font-size: 36px;
			}
		}
	}
	@media screen and (max-width: 850px) {
		#tabs {
			.tab {
				font-size: 30px;
			}
		}
		#features, #map_info {
			flex-direction: column;
		}
		#features_content {
			width: 100%;
			.wrap {
				float: none;
				margin: 20px auto;
			}
			.feat {
				margin: 20px 0;
			}
		}
		#features_gallery {
			width: 100%;
			.dots_container {
				top: 0;
				bottom: auto;
				transform: translateY(0);
				height: auto;
				display: block;
				width: auto;
				.caro_dot {
					display: inline-block;
					margin: 0 10px;
				}
			}
			.caro_slide {
				img {
					width: 100%;
				}
			}
		}
		#facility_info {
			width: 100%;
			font-size: 15px;
		}
		#map_canvas {
			display: none;
		}
	}
	@media screen and (max-width: 750px) {
		#hero {
			height: 400px;
			.type {
				font-size: 70px;
			}
			.storage {
				font-size: 50px;
			}
			#rv {
				padding-top: 90px;
				background-position: 60% 0;
				.type {
					display: block;
					margin-right: 0;
				}
				.storage {
					display: block;
					margin: 0;
				}
			}
			#boat {
				padding-top: 120px;
				.type {
					line-height: 1;
					transform: translateX(0);
				}
			}
			#vehicle {
				padding-top: 90px;
				.type, .storage {
					transform: translate(0);
				}
			}
			.btn {
				width: 260px;
				font-size: 24px;
			}
		}
		#tabs {
			.tab {
				font-size: 16px;
				font-weight: 600;
				&:after {
				    border-width: 25px 25px 0 25px;
				}
				.hide {
					display: none;
				}
				.text {
					padding: 10px 0;
					&:after {
					    border-width: 20px 20px 0 20px;
					}
				}
			}
		}
		#features_content {
			.icon {
				margin: 0 12px;
			}
			.text {
				font-size: 16px;
			}
		}
		#features_gallery {
			.dots_container {
				padding: 5px 10px;
				.caro_dot {
					width: 15px;
					height: 15px;
				}
			}
		}
		#about {
			padding: 50px 20px;
			h1 {
				font-size: 22px;
			}
			p {
				font-size: 14px;
			}
		}
		#facility_info {
			font-size: 13px;
			.wrap {
				margin: 30px 0;
			}
			h2 {
				font-size: 24px;
			}
			.section {
				margin: 20px 0;
			}
		}
	}
}
