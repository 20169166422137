#error.internal{
	#content{
		h4{
			.arm{
				display: inline-block;

				-webkit-animation: arms .3s linear 0s infinite alternate;
				        animation: arms .3s linear 0s infinite alternate;
				&:first-of-type{
					-webkit-transform-origin: right bottom;
					        transform-origin: right bottom;
				}
				&:last-of-type{
					-webkit-transform-origin: left bottom;
					        transform-origin: left bottom;
				}
			}
			.eye{
				display: inline-block;

				-webkit-animation: eye 3s linear 0s infinite alternate;
				        animation: eye 3s linear 0s infinite alternate;
			}
		}
	}
}

@-webkit-keyframes arms{
	from{
		-webkit-transform: skewX(15deg);
		        transform: skewX(15deg);
	}
	to{
		-webkit-transform: skewX(-15deg);
		        transform: skewX(-15deg);
	}
}

@keyframes arms{
	from{
		-webkit-transform: skewX(15deg);
		        transform: skewX(15deg);
	}
	to{
		-webkit-transform: skewX(-15deg);
		        transform: skewX(-15deg);
	}
}

@-webkit-keyframes eye{
	0%{
		-webkit-transform: rotateX(0deg);
		        transform: rotateX(0deg);
	}
	90%{
		-webkit-transform: rotateX(1deg);
		        transform: rotateX(1deg);
	}
	95%{
		-webkit-transform: rotateX(90deg);
		        transform: rotateX(90deg);
	}
	100%{
		-webkit-transform: rotateX(0deg);
		        transform: rotateX(0deg);
	}
}

@keyframes eye{
	0%{
		-webkit-transform: rotateX(0deg);
		        transform: rotateX(0deg);
	}
	90%{
		-webkit-transform: rotateX(1deg);
		        transform: rotateX(1deg);
	}
	95%{
		-webkit-transform: rotateX(90deg);
		        transform: rotateX(90deg);
	}
	100%{
		-webkit-transform: rotateX(0deg);
		        transform: rotateX(0deg);
	}
}