// Faqs Tips
#faq_tips {
	background: url(../../static/images/bg_faqs.jpg) center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: top center;
	overflow: hidden;
	.wrap {
		max-width: 1330px;
		width: auto;
		margin: 0 auto;
	}
	#content {
		max-width: 665px;
		margin: 0;
		padding: 50px 20px;
		background: #fff;
	}
	#tabs {
		.tab {
			display: inline-block;
			font-size: 48px;
			font-weight: 900;
			color: rgba(#333,0.6);
			padding: 5px 25px;
			cursor: pointer;
			transition: color 0.3s;
			box-sizing: border-box;
			&:hover {
				color: #333;
			}
			&:first-child {
				margin-right: 5%;
			}
			&.active {
				color: #333;
				border: 2px solid #333;
			}
		}
	}
	.content {
		display: none;
		&#faqs {
			display: block;
		}
	}
	.qa_item {
		margin: 20px 0;
		h2 {
			position: relative;
			font-size: 18px;
			font-weight: 700;
			padding: 13px 35px 13px 25px;
			color: #333;
			cursor: pointer;
			box-shadow: 0 0 5px 0 rgba(#000,0.5);
			&:after {
				content: "\203A";
				display: block;
				position: absolute;
				right: 15px;
				top: 0;
				font-size: 55px;
				font-weight: 400;
				line-height: 0.7;
				transition: transform 0.3s;
			}
		}
		p {
			display: none;
			padding: 25px;
			background: #eeeeee;
			line-height: 1.4;
		}
		&.active {
			h2:after {
				transform: rotate(90deg);
			}
		}
	}
	@media screen and (max-width: 1024px) {
		background-image: none;
		background-color: #fff;
	}
	@media screen and (max-width: 750px) {
		background: none;
		#content {
			max-width: 100%;
		}
		#tabs {
			.tab {
				font-size: 32px;
				&:first-child {
					margin-right: 2%;
				}
			}
		}
	}
}

// Internal
.internal {
	#content {
		max-width: 1250px;
		width: auto;
		margin: 30px auto;
		padding: 0 20px;
		box-sizing: border-box;
	}
	h1 {
		font-size: 48px;
		font-weight: 800;
		color: #333;
		margin-bottom: 25px;
	}
	@media screen and (max-width: 750px) {
		h1 {
			font-size: 34px;
		}
	}
}

//> About
#about_content {
	max-width: 925px;
	width: auto;
	margin: 30px auto;
	p {
		margin-bottom: 20px;
	}
	#about_gallery {
		margin: 30px auto;
		position: relative;
		overflow: hidden;
		.caro_inner {
			margin: 0;
			width: 100%;
			height: 100%;
			&.caro_dots {
				padding-bottom: 0;
			}
		}
		.dots_container {
			top: 50%;
			left: 0;
			margin: auto;
			width: auto;
			height: 120px;
			padding: 5px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			transform: translateY(-50%);
			background-color: #fff;
			.caro_dot {
				display: block;
				background: rgba($primary,0.5);
				border: 0;
				box-shadow: none;
				&.active {
					background: $primary;
				}
			}
		}
	}
	@media screen and (max-width: 750px) {
		margin: 25px auto;
		#about_gallery {
			.caro_slide {
				img {
					width: 100%;
				}
			}
			.dots_container {
				height: 75px;
				.caro_dot {
					width: 12px;
					height: 12px;
				}
			}
		}
	}
}

//> Pay Bill
#pay_bill {
	.thank_you {
		margin: 20px auto;
		text-align: center;
		font-size: 24px;
		font-weight: 700;
	}
}
