#skip_nav {
	position: absolute;
	left: -9999px;
	height: 1px;
	width: 1px;
	overflow: hidden;
}
#header {
	position: relative;
	z-index: 500;
	background: url(../../static/images/bg_wood.jpg) repeat;
	border-bottom: 15px solid $primary;
	.top {
		max-width: 1145px;
		width: auto;
		margin: 0 auto;
		padding: 40px 10px 20px 10px;
	}
	.contact {
		display: inline-block;
		width: 49%;
		font-weight: 600;
		text-shadow: 0 0 5px rgba(#000,0.9);
		&:last-of-type {
			text-align: right;
		}
		.address {
			color: #fff;
			text-decoration: none;
			-webkit-transition: color 0.4s;
			transition: color 0.4s;
			&:hover {
				color: $accent;
			}
			.icon {
				display: inline-block;
				width: 12px;
				margin-top: 3px;
				margin-right: 5px;
			}
			span {
				display: inline-block;
				font-size: 17px;
			}
		}
		.phone {
			color: #fff;
			text-decoration: none;
			text-align: right;
			-webkit-transition: color 0.4s;
			transition: color 0.4s;
			&:hover {
				color: $accent;
			}
			.icon {
				display: inline-block;
				width: 30px;
			}
			span {
				display: inline-block;
				font-size: 21px;
			}
		}
	}
}
#mobile_nav {
	display: none;
}
#desktop_nav {
	-webkit-box-shadow: inset 0 5px 10px -5px rgba(#000,0.5), inset 0 -5px 10px -5px rgba(#000,0.5);
	        box-shadow: inset 0 5px 10px -5px rgba(#000,0.5), inset 0 -5px 10px -5px rgba(#000,0.5);
	border-top: 10px solid #fff;
	border-bottom: 10px solid #fff;
	background: #fff;
	padding: 15px 0;
	.menu {
		max-width: 1145px;
		width: 100%;
		margin: 0 auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-sizing: border-box;
		        box-sizing: border-box;
	}
	.item {
		position: relative;
		width: calc((100% - 260px)/6);
		text-align: center;
		-ms-flex-item-align: center;
		    -ms-grid-row-align: center;
		    align-self: center;
		border-right: 1px solid #333;
		// -webkit-perspective: 800px;
		// perspective: 800px;
		&:nth-child(3), &:last-child {
			border-right: 0;
		}
		a {
			text-decoration: none;
			font-size: 16px;
			font-weight: 600;
		}
		.link {
			display: block;
			color: #333;
			padding: 5px 0;
			-webkit-transition: background 0.5s;
		    transition: background 0.5s;
		    -webkit-transform-style: preserve-3d;
		    transform-style: preserve-3d;
		    overflow: hidden;
			-webkit-background-clip: padding-box;
		    background-clip: padding-box;
		    &:before {
		    	position: absolute;
		    	z-index: 5;
		    	width: 80%;
			    height: 100%;
		    	right: 0;
			    top: 0;
			    left: 0;
			    margin: 0 auto;
			    padding: 5px 0;
			    -webkit-box-sizing: border-box;
			            box-sizing: border-box;
			    color: #fff;
			    background: $accent;
			    content: attr(data-hover);
			    -webkit-transform: rotateX(270deg);
			            transform: rotateX(270deg);
			    -webkit-transition: -webkit-transform 0.5s;
			    transition: -webkit-transform 0.5s;
			    transition: transform 0.5s;
			    transition: transform 0.5s, -webkit-transform 0.5s;
			    -webkit-transform-origin: 0 0;
			            transform-origin: 0 0;
			    pointer-events: none;
		    }
		    &:after {
		    	position: absolute;
		    	z-index: 6;
		    	width: 80%;
			    height: 100%;
		    	right: 0;
			    top: 0;
			    left: 0;
			    margin: 0 auto;
			    padding: 5px 0;
			    -webkit-box-sizing: border-box;
			            box-sizing: border-box;
			    color: #fff;
			    background: $accent;
			    content: "";
			    -webkit-transform: rotateX(270deg);
			            transform: rotateX(270deg);
			    -webkit-transition: -webkit-transform 0.5s;
			    transition: -webkit-transform 0.5s;
			    transition: transform 0.5s;
			    transition: transform 0.5s, -webkit-transform 0.5s;
			    -webkit-transform-origin: 0 0;
			            transform-origin: 0 0;
			    pointer-events: none;
		    }
		    &:hover {
		    	&:before {
		    		-webkit-transform: rotateX(10deg);
				    transform: rotateX(10deg);
		    	}
		    	&:after {
		    		-webkit-transform: rotateX(10deg);
				    transform: rotateX(10deg);
				    z-index: 1;
		    	}
		    }
		}
		.pay_btn {
			display: block;
			color: #fff;
			padding: 5px 0;
			border-radius: 4px;
			max-width: 110px;
			margin: 0 auto;
			background: $accent;
			background: -webkit-gradient(linear, left top, left bottom, from($accent), to($accent_dark));
			background: linear-gradient(to bottom, $accent, $accent_dark);
			background-size: 300%;
			-webkit-transition: background-size 0.4s;
			transition: background-size 0.4s;
			&:hover {
				background-size: 100%;
			}
		}
	}
	.logo {
		width: 260px;
		position: relative;
		img {
			display: block;
			position: absolute;
			top: -103px;
			width: auto;
			max-width: 100%;
		}
	}
}

@media screen and (max-width: 940px) {
	#desktop_nav {
		.item {
			a {
				font-size: 12px;
			}
			.link {
				&:before {
					width: 100%;
				}
			}
			.pay_btn {
				max-width: 65px;
			}
		}
		.logo {
			img {
				top: -104px;
			}
		}
	}
}
@media screen and (max-width: 750px) {
	#desktop_nav {
		display: none;
	}
	#header {
		.top {
			padding: 10px;
			text-align: right;
		}
		.contact {
			width: auto;
			margin-right: 5px;
			.address {
				.icon {
					width: 18px;
				}
				span {
					display: none;
				}
			}
			.phone {
				.icon {
					width: 38px;
				}
				span {
					display: none;
				}
			}
		}
	}
	#mobile_nav {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: distribute;
		    justify-content: space-around;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		-webkit-box-shadow: inset 0 0 5px 0 rgba(#000,0.7);
	        box-shadow: inset 0 0 5px 0 rgba(#000,0.7);
		border-top: 7px solid #fff;
		border-bottom: 7px solid #fff;
		background: #fff;
		padding: 15px 0;
		.logo {
			position: relative;
			display: block;
			width: 200px;
			img {
				display: block;
				position: absolute;
				top: -87px;
				max-width: 100%;
			}
		}
		.mobile_menu {
			position: relative;
			display: block;
			width: 35px;
			height: 24px;
			cursor: pointer;
			-webkit-transition: -webkit-transform 1s;
			transition: -webkit-transform 1s;
			transition: transform 1s;
			transition: transform 1s, -webkit-transform 1s;
			.line {
				display: block;
				position: absolute;
				top: 50%;
				-webkit-transform: translateY(-50%);
				        transform: translateY(-50%);
				width: 100%;
				height: 4px;
				background: $primary_light;
			}
			&:before, &:after {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 4px;
				background: $primary_light;
				-webkit-transition: top 0.2s 0.25s, -webkit-transform 0.25s;
				transition: top 0.2s 0.25s, -webkit-transform 0.25s;
				transition: transform 0.25s, top 0.2s 0.25s;
				transition: transform 0.25s, top 0.2s 0.25s, -webkit-transform 0.25s;
			}
			&:after {
				top: auto;
				bottom: 0;
				-webkit-transition: bottom 0.2s 0.25s, -webkit-transform 0.25s;
				transition: bottom 0.2s 0.25s, -webkit-transform 0.25s;
				transition: transform 0.25s, bottom 0.2s 0.25s;
				transition: transform 0.25s, bottom 0.2s 0.25s, -webkit-transform 0.25s;
			}
			&.close {
				-webkit-transform: rotate(720deg);
				        transform: rotate(720deg);
				.line {
					visibility: hidden;
				}
				&:before {
					top: 40%;
					-webkit-transform: rotate(45deg);
					        transform: rotate(45deg);
					background: $accent_dark;
					-webkit-transition: top 0.2s, -webkit-transform 0.25s 0.2s;
					transition: top 0.2s, -webkit-transform 0.25s 0.2s;
					transition: top 0.2s, transform 0.25s 0.2s;
					transition: top 0.2s, transform 0.25s 0.2s, -webkit-transform 0.25s 0.2s;
				}
				&:after {
					bottom: 40%;
					-webkit-transform: rotate(-45deg);
					        transform: rotate(-45deg);
					background: $accent_dark;
					-webkit-transition: bottom 0.2s, -webkit-transform 0.25s 0.2s;
					transition: bottom 0.2s, -webkit-transform 0.25s 0.2s;
					transition: bottom 0.2s, transform 0.25s 0.2s;
					transition: bottom 0.2s, transform 0.25s 0.2s, -webkit-transform 0.25s 0.2s;
				}
			}
		}
		.menu {
			position: absolute;
			top: calc(100% + 15px);
			right: 5px;
			width: 200px;
			-webkit-perspective: 10000px;
			perspective: 10000px;
			max-height: 0;
			overflow: hidden;
			-webkit-transition: max-height 1s;
			transition: max-height 1s;
			&.open {
				max-height: 1000px;
				-webkit-transition: max-height 2s;
				transition: max-height 2s;
			}
			li {
				display: block;
				-webkit-transition: background 0.5s;
			    transition: background 0.5s;
			    -webkit-transform-style: preserve-3d;
			    transform-style: preserve-3d;
				a {
					position: relative;
					border-left: 2px solid #a97e62;
					border-right: 2px solid #a97e62;
					display: block;
					color: #fff;
					font-size: 15px;
					font-weight: 600;
					text-decoration: none;
					padding: 17px 20px;
				    -webkit-transform: rotateX(270deg) translateZ(0);
				            transform: rotateX(270deg) translateZ(0);
				    -webkit-transition: -webkit-transform 0.5s;
				    transition: -webkit-transform 0.5s;
				    transition: transform 0.5s;
				    transition: transform 0.5s, -webkit-transform 0.5s;
				    -webkit-transform-origin: 0 0;
				            transform-origin: 0 0;
				    span {
				    	display: block;
				    	position: relative;
				    	z-index: 5;
				    }
				    &:after {
				    	content: "";
				    	position: absolute;
				    	z-index: 10;
				    	top: 0;
				    	left: 0;
				    	width: 100%;
				    	height: 100%;
				    	display: block;
						background: url(../../static/images/bg_plank.jpg);
					    -webkit-transform: rotateX(270deg);
					            transform: rotateX(270deg);
					    -webkit-transition: -webkit-transform 0.5s;
					    transition: -webkit-transform 0.5s;
					    transition: transform 0.5s;
					    transition: transform 0.5s, -webkit-transform 0.5s;
					    -webkit-transform-origin: 0 0;
					            transform-origin: 0 0;
				    }
				    &.active, &.active:after {
						-webkit-transform: rotateX(10deg) translateZ(0);
						        transform: rotateX(10deg) translateZ(0);
						-webkit-transition: -webkit-transform 0.5s;
						transition: -webkit-transform 0.5s;
						transition: transform 0.5s;
						transition: transform 0.5s, -webkit-transform 0.5s;
				    }
				    &.active:after {
				    	z-index: 3;
				    }
				}
			}
		}
	}
}