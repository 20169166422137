#footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 15px;
		background: -webkit-gradient(linear, left top, right top, from(#fff), color-stop(#959595), to(#fff));
		background: linear-gradient(to right, #fff, #959595, #fff);
		-webkit-box-shadow: inset 0 -4px 5px -3px rgba(#000,0.5);
		        box-shadow: inset 0 -4px 5px -3px rgba(#000,0.5);
	}
	.menu {
		-webkit-box-shadow: inset 0 0 10px 0 rgba(#000,0.5);
	    box-shadow: inset 0 0 10px 0 rgba(#000,0.5);
		border-top: 10px solid #fff;
		border-bottom: 10px solid #fff;
		background: #fff;
		padding: 20px 0;
		ul {
			max-width: 1145px;
			width: auto;
			margin: 0 auto;
			text-align: center;
		}
		li {
			display: inline;
			margin: 0 2%;
			a {
				display: inline-block;
				padding: 0 1%;
				text-decoration: none;
				font-size: 16px;
				font-weight: 600;
				color: #333;
				-webkit-transition: color 0.4s;
				transition: color 0.4s;
				&:hover {
					color: $accent;
				}
			}
		}
	}
	.copyright {
		text-align: center;
		padding: 30px 0;
		background: url(../../static/images/bg_wood.jpg) repeat;
		span {
			display: inline-block;
			font-size: 12px;
			color: #fff;
			margin-top: 9px;
		}
	}
	@media screen and (max-width: 750px) {
		.menu {
			display: none;
		}
	}
}