//> Fonts
$montserrat: 'Montserrat', sans-serif;

//> Colors
$primary: #42210b; // dark brown
$primary_light: #603813; // light brown
$accent: #e93237; // red
$accent_dark: #a93335; // dark red

body {
	color: #333;
	font: 16px $montserrat;
}
a {
	color: $accent;
}

#container {
	position: relative;
	margin: 0 auto;
	min-height: 100%;
	box-sizing: border-box;
	padding-bottom: 178px;
	@media screen and (max-width: 750px) {
		padding-bottom: 104px;
	}
}

.btn {
	display: inline-block;
	position: relative;
	color: #fff;
	text-decoration: none;
	font: {
		weight: 800;
		family: $montserrat;
	}
	text-align: center;
	box-sizing: border-box;
	background: linear-gradient(to bottom, $accent, $accent_dark);
	background-size: 100%;
	border: 3px solid #fff;
	transition: background-size 0.4s, text-shadow 0.35s;
	&:hover {
		background-size: 150%;
		text-shadow: 0 3px 6px rgba(#000,0.6);
	}
}
