#contact {
	#info_map {
		display: flex;
		.contact_info {
			width: 50%;
			box-sizing: border-box;
		}
		.wrap {
			float: right;
			padding: 40px 20px;
			width: 100%;
			box-sizing: border-box;
			max-width: 570px;
		}
		h2 {
			font-size: 36px;
			font-weight: 700;
			margin-top: 30px;
		}
		p {
			margin: 20px 0;
		}
		.section {
			margin: 25px 0;
			strong {
				display: block;
				font-weight: 700;
			}
			a {
				color: #333;
				text-decoration: none;
				display: inline-block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				transition: color 0.3s;
				&:hover {
					color: $accent;
				}
			}
		}
	}
	#map_canvas {
		width: 50%;
	}
	#form {
		width: auto;
		padding: 50px 20px;
		background: url(../../static/images/bg_contact.jpg) center;
		background-size: cover;
		.wrap {
			max-width: 980px;
			margin: 0 auto;
		}
		h2 {
			color: #fff;
			font-size: 48px;
			font-weight: 700;
			text-align: center;
		}
		.response_message {
			color:#fff;
			background:#000;
		}
		strong {
			display: block;
			font-size: 36px;
			text-align: center;
			color: #fff;
			margin-bottom: 25px;
		}
		.hpwrap {
			position: absolute;
			left: -999px;
			overflow: hidden;
			width: 1px;
			height: 1px;
		}
		.input {
			position: relative;
			overflow: hidden;
			margin-bottom: 20px;
			background: #fff;
		}
		label {
			position: absolute;
			top: 12px;
			left: 0;
			padding: 0 20px;
			width: 100%;
			font: {
				size: 15px;
				family: $montserrat;
			}
			color: #818181;
			box-sizing: border-box;
			transition: top 0.3s, font-size 0.3s, background 0.3s;;
		}
		input, textarea {
			position: relative;
			z-index: 1;
			display: block;
			width: 100%;
			background: none;
			font: {
				size: 15px;
				family: $montserrat;
			}
			padding: 15px 20px;
			box-sizing: border-box;
			border: 0;
			&:focus + label {
				top: calc(100% - 13px);
				font-size: 10px;
				color: #333;
				z-index: 2;
				background: #dcdcdc;
			}
			&:valid {
				background: #fff;
			}
			&.error {
				border: 1px solid #ff0000;
			}
		}
		textarea {
			height: 150px;
		}
		.inputs {
			display: flex;
			justify-content: space-between;
			.input {
				width: 49%;
			}
		}
		.response_message {
			.success {
				display: block;
				color: #333;
				text-align: center;
				padding: 20px 0;
				font-size: 18px;
				font-weight: 700;
				background: #afccab;
			}
			.error {
				display: block;
				color: #fff;
				text-align: right;
				padding: 15px 0;
			}
		}
		#contact_form_submit {
			display: block;
			width: 100%;
			max-width: 480px;
			float: right;
			padding: 15px 0;
			cursor: pointer;
		}
		#contact_form:after {
			content: "";
			display: block;
			clear: both;
		}
	}

	@media screen and (max-width: 750px) {
		#info_map {
			.contact_info {
				width: 100%;
				.wrap {
					float: none;
				}
			}
			h2 {
				font-size: 28px;
			}
			.section {
				a {
					display: block;
				}
			}
		}
		#map_canvas {
			display: none;
		}
		#form {
			padding: 30px 15px;
			h2 {
				font-size: 40px;
			}
			strong {
				font-size: 30px;
			}
		}
	}
}