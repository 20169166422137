#rates {
	background: url(../../static/images/bg_rates.jpg) center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: top center;
	overflow: hidden;
	.wrap {
		max-width: 1340px;
		width: auto;
		margin: 0 auto;
	}
	#content {
		max-width: 665px;
		margin: 0;
		padding: 50px 20px;
		background: #fff;
	}
	#rates_chart {
		width: 100%;
		margin: 30px 0;
		.category {
			font-size: 14px;
			font-weight: 700;
			color: $primary;
			text-align: center;
			td {
				padding: 20px 0;
			}
		}
		.unit_row {
			&:nth-child(even) {
				background: #eeeeee;
			}
			td {
				vertical-align: middle;
			}
			.unit_size {
				font-size: 24px;
				font-weight: 700;
				text-align: center;
			}
			.unit_details {
				font-size: 10px;
				padding: 15px 5px;
				li {
					margin: 3px 0 3px 20px;
					list-style: disc;
				}
			}
			.special_offer {
				text-align: center;
				font-size: 13px;
				padding: 10px 5px;
			}
			.monthly_rent {
				text-align: center;
				.old {
					display: block;
					font-size: 12px;
					text-decoration: line-through;
				}
				.now {
					font-size: 24px;
					font-weight: 700;
				}
			}
			.rate_button {
				text-align: center;
				padding: 15px 5px;
				.mobile_arrow {
					display: none;
				}
				.btn {
					display: block;
					font-size: 15px;
					padding: 10px 0;
				}
				.reserve {
					font-size: 13px;
					font-weight: 700;
					color: $primary;
					transition: color 0.3s;
					&:hover {
						color: $accent;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		background-image: none;
		background-color: #fff;
	}
	@media screen and (max-width: 750px) {
		background: none;
		h1 {
			padding-left: 10px;
		}
		#content {
			padding: 30px 10px;
		}
		#rates_chart {
			margin: 10px 0;
			overflow: hidden;
			.hide {
				display: none;
			}
			.category {
				font-size: 10px;
				font-weight: 600;
			}
			.unit_row {
				transition: transform 0.5s;
				&.slide {
					transform: translateX(-230px);
				}
				.unit_size, .monthly_rent .now {
					font-size: 16px;
					font-weight: 600;
				}
				.special_offer {
					font-size: 12px;
				}
				.rate_button {
					position: relative;
					.mobile_arrow {
						display: block;
						font-size: 40px;
						color: $accent;
					}
					.btn_container {
						position: absolute;
						top: 0;
						left: 100%;
						width: 230px;
						height: 100%;
						display: flex;
						justify-content: space-around;
						align-items: center;
						background: #fff;
					}
					.btn {
						padding: 10px;
						font-size: 12px;
						font-weight: 600;
					}
					.reserve {
						font-size: 12px;
						font-weight: 600;
						display: inline-block;
						padding: 10px;
						color: #fff;
						text-decoration: none;
						background: linear-gradient(to bottom, $primary_light, $primary);
					}
				}
			}
		}
	}
}
